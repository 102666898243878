.flexbox-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.tabs-flexbox-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}

.flexbox-item {
  flex: 1 1 auto;
  flex-grow: 1;
  flex-basis: auto;
  align-self: stretch;
}

.code-preview {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}
input,
textarea {
  border: solid 2px #666;
  border-radius: 2px;
}

.activeTab {
  background-color: lightgrey;
  -moz-border-radius: 0px;
  -webkit-border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
}

.clickable {
  cursor: pointer;
}

h1 {
  font-size: large;
  padding: 2px;
}
